import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../textUtils/Heading";
import OurWork from '../needCompo/OurWork'

export default function TermsCondition() {
  return (
    <React.Fragment>
      <Container fluid className="py-5">        
          <OurWork/>
      </Container>
    </React.Fragment>
  );
}
