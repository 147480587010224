import React, { Component, useState } from "react";

export default function ReviewPopup() {
  const [modal, setModal] = useState(false);

  const style = {
    background: "#00000080",
    display: "block",
  };

  const review = {
    position: "fixed",
    bottom: "4%",
    right: "0%",
    zIndex: "10999",
    background: "linear-gradient(to right, blue, red)",
    boxShadow:
      "rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px",
    overflow: "hidden",
    color: "white",
    borderRadius: "5px",
    textAlign: "center",
  };

  const btn = {
    color: "white",
  };

  const openData = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-popup");
  } else {
    document.body.classList.remove("active-popup");
  }

  return (
    <>
      <div className="reviewBtn" style={review} >
        <a href="https://g.page/r/CfwRPdPnskRaEB0/review" target="_blank">
          <i className="fa fa-star btn" style={btn}>
            {" "}
            Add Review{" "}
          </i>
        </a>
      </div>

      {modal && (
        <div className="modal reviewModal" style={style}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Review
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={openData}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="recipient-name" className="col-form-label">
                      Enter Name :
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="review_name"
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="message-text" className="col-form-label">
                      Write Review :
                    </label>
                    <textarea
                      className="form-control"
                      id="message-text"
                      placeholder="Write here..."
                    ></textarea>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary">
                  Submit Review
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
