import React from 'react';
import logo from "../../assets/banner/contactus.webp";
import ContactForm from '../contactForm/ContactForm';
import Heading from '../textUtils/Heading';

export default function ContactPage(){
    return (
        <React.Fragment>
        
        <div className='container-fluid  about_header'>
            <div className='row'>
                <div className='col-md-12 p-0'>
                    <img src={logo} className='img-fluid' alt='main logo'/>
                </div>
            </div>

            <div className='row mb-5 d-md-none mobile_getintouch'>
                <div className='col-md-10 mx-auto'>
                <Heading subheading="Get In Touch" heading="Contact Info"/>

                <p>
                    We have world-class, flexible support via live chat, email, and phone calls.
                    We guarantee that you'll be able to have any issue resolved within 24 hours.
                </p>
                <ul className='contact'>
                    <li>Phone:<br/> +91-6396617714</li>
                    <li>Email:<br/> info@vipsgrow.in</li>
                    <li>Address:<br/> Saket Colony, Hathras(204101)</li>
                </ul> 
                </div>
            </div>

            <div className='getintouch'>
                <div className='row'>
                    <div className='col-md-12'>
                        <Heading subheading="Get In Touch" heading="Contact Info"/>

                        <p>
                            We have world-class, flexible support via live chat, email, and phone calls.
                            We guarantee that you'll be able to have any issue resolved within 24 hours.
                        </p>
                        <ul className='contact'>
                            <li>Phone:<br/> +91-6396617714</li>
                            <li>Email:<br/> info@vipsgrow.in</li>
                            <li>Address:<br/> Saket Colony, Hathras(204101)</li>
                        </ul>                        


                    </div>
                </div>
            </div>
        </div>

        <ContactForm subheading = "Contact Us" heading="Request To Call Back"/>
        
        
        </React.Fragment>
    )
}