import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/image/viplogo.webp";

export default function Footer() {
  return (
    <footer className="footer_div py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-3 mb-3 col-10 mx-auto text-justify">
            <img src={logo} className="img-fluid App-logo mb-3" />
            <p>
              We Focus on the needs of small to middle market businessess to
              improve and grow their return.
            </p>
            <p>
            We are on a mission to build a better future where every business will have its own digital shop on the web or internet
            </p>

            <ul className="social_button">
              <li>
                {" "}
                <a href="https://www.facebook.com/vipsgrow/">
                  <i className="fa fa-facebook fa-2x"></i>
                </a>
              </li>
              {/* <li>
                <a href="">
                  <i className="fa fa-instagram"></i>
                </a>
              </li> */}
              <li>
                <a href="">
                  <i className="fa fa-instagram fa-2x"></i>
                </a>
              </li>
              <li>
                <a href="https://wa.me/6396617714" target="_blank">
                  <i className="fa fa-whatsapp fa-2x"></i>
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-3 mb-3 menu col-5 mx-auto">
            <h5>Services</h5>
            <ul>
              <li>
                {" "}
                <Link to="/serviceDetails/website"> Websites </Link>
              </li>
              <li>
                {" "}
                <Link to="/serviceDetails/application"> Mobile Application </Link>
              </li>
              <li>
                {" "}
                <Link to="/serviceDetails/hosting"> Hosting </Link>
              </li>
              <li>
                {" "}
                <Link to="/serviceDetails/server"> Server </Link>
              </li>              
            </ul>
          </div>

          <div className="col-md-3 mb-3 menu col-5 mx-auto">
            <h5>Quick Link</h5>
            <ul>
              <li>
                {" "}
                <Link to="/about"> About Us </Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/service"> Services </Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/our-projects"> Our Projects </Link>{" "}
              </li>
              {/* <li>
                {" "}
                <Link to="/plan"> Pricing Plan </Link>{" "}
              </li> */}
              <li>
                {" "}
                <Link to="/contact-us"> Contact Us </Link>{" "}
              </li>
            </ul>
          </div>

          <div className="col-md-3 mb-3 menu col-10 mx-auto">
            <h5>Details</h5>
            <ul>
              <li>
                {" "}
                <Link to="/privacy-policy"> Privacy Policy </Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/terms-condition"> Terms & Condition </Link>{" "}
              </li>
              <li>
                {" "}
                <Link to="/refund-policy"> Refund Policy </Link>{" "}
              </li>
              {/* <li>
                {" "}
                <Link to="/plan"> Pricing Plan </Link>{" "}
              </li> */}
              
            </ul>


          </div>
        </div>
      </div>
    </footer>
  );
}
