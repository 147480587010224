import React from 'react'
import {  Row, Col } from "react-bootstrap";

export default function Hosting(){
    return (
        <React.Fragment>
            <Row>
        <Col md={10} className="offset-application mx-auto" id="apk_div">
          <div className="pricing_table py-5">
            <Row>
              <Col md={4}>
                <div className="table-data">
                  <h4>Shared Hosting</h4>
                  <h3 className="table-price">1,999</h3>
                  <ul>
                    <li>1 Website</li>
                    <li>2 Sub Domain</li>
                    <li>1 TB Bandwith</li>
                    <li>2 MySQL Database</li>
                    <li>Free SSL Certificates</li>
                    <li>24/7 Technical Support</li>
                  </ul>
                  <h3 className="table-price">
                    Get Started
                  </h3>
                </div>
              </Col>

              <Col md={4}>
                <div className="table-data">
                  <h4>Hosting</h4>
                  <h3 className="table-price">2,999</h3>
                  <ul>
                    <li>5 Website</li>
                    <li>10 Sub Domain</li>
                    <li>1 TB Bandwith</li>
                    <li>10 MySQL Database</li>
                    <li>Free SSL Certificates</li>
                    <li>24/7 Technical Support</li>
                  </ul>
                  <h3 className="table-price">
                    Get Started
                  </h3>
                </div>
              </Col>

              <Col md={4}>
                <div className="table-data">
                  <h4>Premium Hosting</h4>
                  <h3 className="table-price">9,999</h3>
                  <ul>
                    <li>Unlimited Website</li>
                    <li>Unlimited Sub Domain</li>
                    <li>Unlimited Bandwith</li>
                    <li>Unlimited Database</li>
                    <li>Free SSL Certificates</li>
                    <li>24/7 Technical Support</li>
                  </ul>
                  <h3 className="table-price">
                    Get Started
                  </h3>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
        </React.Fragment>
    )
}