import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";

import b1 from "../../assets/banner/b1.webp";
import b2 from "../../assets/banner/b2.webp";
import b3 from "../../assets/banner/b3.webp";

export default function HomeSlider() {
  return (
    <React.Fragment>
      <Container fluid className="mainSliderContainer">
        <Row >
          <Col md={12} className="p-0 ">
            <Carousel>
              <Carousel.Item>
                <img className="d-block w-100" src={b1} alt="First slide" /> 
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={b2} alt="Second slide" />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={b3} alt="Third slide" />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
