import React from 'react'


const d = new Date().getFullYear();

export default function Copyright(){
    return(
        <React.Fragment>

        

        <div className='copyright_div container-fluid text-center py-2'>
            <div className='row'>
                <div className='col-md-6 col-10 mx-auto'>
                    Copyright Issue - {d} - VIPsGrow. All Rights Reserverd.
                </div>
            </div>
            
        </div>
        </React.Fragment>
    )
}