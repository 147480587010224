import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import logo from "../../viplogo.png";
import "../../css/style.css";
import Heading from "../textUtils/Heading";
import a1 from "../../assets/banner/about-right.png";
import about_main from "../../assets/banner/about-us-main.webp";
import a2 from "../../assets/banner/process.png";
import web from "../../assets/image/web-programming.webp";
import webho from "../../assets/image/monitoring.webp";

import "../../css/serviced.css";

export default function About() {
  return (
    <React.Fragment>
      <Container fluid>
        <Row className="">
          <Col md={12} className="p-0">
            <div className="top-heading">
              <img src={about_main} className="img-fluid" alt="logo" />
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className="py-5 about-page-section2">
          <Col md={10} className="mx-auto">
            <Heading subheading="TALK ABOUT VIPSGROW" heading="WHO WE ARE ?" />
          </Col>

          <Col md={10} className="mx-auto">
            <Row>
              <Col md={4}>
                <img src={logo} className="img-fluid" alt="logo" />
              </Col>

              <Col md={8}>
                <p>
                  We are a company who deal in information technology services.
                  VIP Grow started in 2018 and corporated in 2022. <br />
                  (GSTIN - 09KSYPS4627H1ZL, MSME - UDYAM-UP-36-0002762)
                  <br />
                  We want every business to have its own digital shop, its own
                  website. So that it can go global. Its service area can be
                  broadened. Digital shop like profiling website, e-commerce
                  website, Android application etc.
                </p>
                <p>
                  Along with this, he should have an accurate management
                  software so that he can manage all this.
                  <br />
                  With this, we try to fly you on the web with your ideas.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container className="about-section3">
        <Row className="py-5">
          <Col md={6}>
            <img src={a1} className="img-fluid" alt="logo" />
          </Col>
          <Col md={6} className="my-auto">
            <Heading
              subheading="TALK ABOUT VIPSGROW"
              heading="OUR REPUTATION"
            />
            <p>
              We have completed many projects with our happily valuable clients.
              We just started to make better relationships with clients. Our
              mission is to maintain these success numbers in further.
            </p>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row className=" card-section-about">
          <Col md={10} className="mx-auto">
            <Row className="p-0">
              <Col md={3} className=" my-3 mx-auto ">
                <Card className="py-3 service_card">
                  <div className=" service_item mx-auto">
                    <i className="fa fa-handshake-angle fa-3x"></i>
                    {/* <img src={p} width="100" className="off" alt="" />
                        <img src={pho} width="100" className="on" alt="" /> */}
                  </div>
                  <span className="google mt-3">Customer Satisfaction</span>
                  <span className="text mt-2">
                    Customer satisfaction is our first priority, Satisfied.
                    <br />
                    Customers are our main assets.
                  </span>
                </Card>
              </Col>

              <Col md={3} className=" my-3 mx-auto ">
                <Card className="p-3 service_card">
                  <div className=" service_item mx-auto">
                    <img src={web} width="100" className="off" alt="" />
                    <img src={webho} width="100" className="on" alt="" />
                  </div>
                  <span className="google mt-3">Relationship</span>
                  <span className="text mt-2">
                    We treat our clients like a family for feeling them comfort
                    to explore their ideas.
                  </span>
                </Card>
              </Col>

              <Col md={3} className=" my-3 mx-auto ">
                <Card className="p-3 service_card">
                  <div className=" service_item mx-auto">
                    <img
                      src={require("../../assets/image/seo.webp")}
                      width="100"
                      className="off"
                      alt=""
                    />
                    <img
                      src={require("../../assets/image/seo (1).webp")}
                      width="100"
                      className="on"
                      alt=""
                    />
                  </div>
                  <span className="google mt-3">Faith</span>
                  <span className="text mt-2">
                    Satisfaction and relationship build trust, and we believe
                    in, Which makes us trustworthy.
                  </span>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col md={10} className="mx-auto">
            <Row>
              <Col md={6} className="py-5 mx-auto">
                <Heading
                  subheading="TALK ABOUT VIPSGROW"
                  heading="HOW WE WORK ?"
                />
                <div className="p-5">
                  <Heading subheading="Organizational Management" heading="" />
                  <p className="p-3">
                    We have 100+ team members in our company and these team
                    members are managed by our highly experienced team leader.
                  </p>
                  <Heading heading="Focus Energy and Resources" />
                  <p className="p-3">
                    Our focus is solely on work, and we get energy from the
                    client's positive feedback and we use team member's ideas as
                    a source to develop better content.
                  </p>

                  <Heading heading="Developing Critical Elements" />
                  <p className="p-3">
                    We have highly qualified developers and technical teams to
                    deal with critical elements.
                  </p>
                </div>
              </Col>

              <Col md={6} className="my-auto ">
                <img src={a2} className="img-fluid" alt="logo" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
