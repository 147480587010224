import React from "react";
import Heading from "../textUtils/Heading";

import cStar from "../../assets/back/code.webp";
import star from "../../assets/back/star.webp";

import graStar from "../../assets/back/android.webp";
import RandomImage from "../randomMove/RandomImage";

import { Container, Row, Col } from "react-bootstrap";

export default function TabList(props) {
  return (
    <>
      <Container fluid className="p-md-5 tablist">
        <RandomImage
          op="back"
          myImg1={cStar}
          myImg2={star}
          myImg3={star}
          myImg4={graStar}
          myImg5={star}
          myImg6={graStar}
        />
        <Row>
          <Col md={10} className="mx-auto">
            <Heading subheading="EXPERT IN ...." heading="WHY CHOOSE US" />
          </Col>
        </Row>

        <Row className="about-section">
          <div className="experience tab-content">
            <div className="row">
              <div className="timeline">
                <div className="row">
                  <div className="timeline-item">
                    <div className="timeline-item-inner outer-shadow">
                      <i className="fa fa-check icon"></i>
                      <span>Never Compromise on Quality</span>
                      <h3>Hight Quality Work</h3>
                      <p>
                        We try to give the best quality of work. Also advise the
                        customer what is right for growing the business.
                      </p>
                    </div>
                  </div>

                  <div className="timeline-item">
                    <div className="timeline-item-inner outer-shadow">
                      <i className="fa fa-shield icon"></i>
                      <span>Never Mislead The Customer</span>
                      <h3>Aglie and Fast Working Style</h3>

                      <p>
                        We work with agreements that specify the time period for
                        the completed work.
                      </p>
                    </div>
                  </div>

                  <div className="timeline-item">
                    <div className="timeline-item-inner outer-shadow">
                      <i className="fa fa-crop icon"></i>
                      <span>Best Designs</span>
                      <h3>UX / UI (Design & Development)</h3>
                      <p>
                        Designed, Developed, modified 25+ software systems and
                        custom compnents.
                      </p>
                    </div>
                  </div>

                  <div className="timeline-item">
                    <div className="timeline-item-inner outer-shadow">
                      <i className="fa fa-android icon"></i>
                      <span>Best Performance</span>
                      <h3>Android Application Development</h3>
                      <p>
                        We work on native android application. So that the
                        customer does not face any problem in the performance of
                        the application and the user experience is also good
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
