import React from 'react'
import "../../css/moving.css";

const RandomImage = (props) =>{    
    return (
        <React.Fragment>
            <img src={props.myImg1} className="move one " id={props.op} alt=""/>
            <img src={props.myImg2} className="move two" id={props.op} alt=""/>
            <img src={props.myImg3} className="move three" id={props.op} alt=""/>
            <img src={props.myImg4} className="move four" id={props.op} alt=""/>
            <img src={props.myImg5} className="move five"  id={props.op} alt=""/>
            <img src={props.myImg6} className="move six" id={props.op} alt=""/>
        </React.Fragment>
    )
}
export default RandomImage;