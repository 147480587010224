import React from "react";
import Heading from "../textUtils/Heading";
import { Container, Row, Col, Card } from "react-bootstrap";

import back from "../../assets/back/circle.webp";
import cStar from "../../assets/back/star.webp";
import graStar from "../../assets/back/stargray.webp";
import p from "../../assets/image/project-management.webp";
import pho from "../../assets/image/project-management (1).webp";
import web from "../../assets/image/web-programming.webp";
import webho from "../../assets/image/monitoring.webp";
import { Link } from "react-router-dom";
import RandomImage from "../randomMove/RandomImage";

export default function Service() {
  return (
    <React.Fragment>
      <Container fluid className="py-5 service">
        <img src={back} className="back_circle left" alt="" />
        <RandomImage
          op="front"
          myImg1={cStar}
          myImg2={graStar}
          myImg3={cStar}
          myImg4={graStar}
          myImg5={cStar}
          myImg6={graStar}
        />
        <Row>
          <Col md={10} className="mx-auto">
            <Heading subheading="WE GLAD TO GIVE " heading="OUR SERVICES" />
          </Col>
        </Row>

        <Row>
          <Col md={10} className="mx-auto my-2 g-2">
            <Row>
              <Col md={4} lg={3} className=" my-lg-3 my-1 mx-auto ">
                <Card className="p-3 service_card">
                  <div className=" service_item mx-auto">
                    <img src={p} className="service-image off" alt="" />
                    <img src={pho} className="service-image on" alt="" />
                  </div>
                  <h3 className="google mt-3">IT Solution</h3>
                  <span className="text mt-2">
                    If you need to make your business online (web), we provide
                    consultancy to improve it. <br /> Contact Now
                  </span>

                  <a href="tel:+916396617714">
                    <p className="btn read_more">Contact Us</p>
                  </a>
                </Card>
              </Col>

              <Col md={4} lg={3} className=" my-lg-3 my-1 mx-auto ">
                <Card className="p-3 service_card">
                  <div className=" service_item mx-auto">
                    <img src={web} className="service-image off" alt="" />
                    <img src={webho} className="service-image on" alt="" />
                  </div>
                  <h3 className="google mt-3">Web Development</h3>
                  <span className="text mt-2">
                    We're working to bring your business live on the web. And he
                    tries his best to become a successful business.
                  </span>
                  <Link to="/serviceDetails/Website">
                    <p className="btn read_more">Read More</p>
                  </Link>
                </Card>
              </Col>

              <Col md={4} lg={3} className=" my-lg-3 my-1 mx-auto ">
                <Card className="p-3 service_card">
                  <div className=" service_item mx-auto">
                    <img
                      src={require("../../assets/image/seo.webp")}
                      width="100"
                      className="service-image off"
                      alt=""
                    />
                    <img
                      src={require("../../assets/image/seo (1).webp")}
                      width="100"
                      className="service-image on"
                      alt=""
                    />
                  </div>
                  <h3 className="google mt-3">SEO Optimization</h3>
                  <span className="text mt-2">
                    We works to get your business to the top of the keyword
                    rankings. Our seo expert team does the best work for top
                    ranking your website.
                  </span>
                  <a href="tel:+916396617714">
                    <p className="btn read_more">Contact Us</p>
                  </a>
                </Card>
              </Col>
            </Row>
            <Row>
            <Col md={4} lg={3} className=" my-lg-3 my-1 mx-auto ">
                <Card className="p-3 service_card">
                  <div className=" service_item mx-auto">
                    <img
                      src={require("../../assets/image/android.webp")}
                      width="100"
                      className="service-image off"
                      alt=""
                    />
                    <img
                      src={require("../../assets/image/android (1).webp")}
                      width="100"
                      className="service-image on"
                      alt=""
                    />
                  </div>
                  <h3 className="google mt-3">Android Development</h3>
                  <span className="text mt-2">
                    The future of mobile is the future of online. This is how
                    people access online content now. We take your business to
                    everyone through mobile APK.
                  </span>
                  <Link to="/serviceDetails/Application">
                    <p className="btn read_more">Read More</p>
                  </Link>
                </Card>
              </Col>

              <Col md={4} lg={3} className=" my-lg-3 my-1 mx-auto ">
                <Card className="p-3 service_card">
                  <div className=" service_item mx-auto">
                    <img
                      src={require("../../assets/image/bullhorn.webp")}
                      width="100"
                      className="service-image off"
                      alt=""
                    />
                    <img
                      src={require("../../assets/image/bullhorn (1).webp")}
                      width="100"
                      className="service-image on"
                      alt=""
                    />
                  </div>
                  <h3 className="google mt-3">Digital Marketing</h3>
                  <span className="text mt-2">
                    With the help of social media, your business really
                    flourishes with a large number of people. Grow your business
                    on digital.
                  </span>
                  <Link to="/">
                    <p className="btn read_more">Read More</p>
                  </Link>
                </Card>
              </Col>

              <Col md={4} lg={3} className=" my-lg-3 my-1 mx-auto ">
                <Card className="p-3 service_card">
                  <div className=" service_item mx-auto">
                    <img
                      src={require("../../assets/image/curve.webp")}
                      width="100"
                      className="service-image off"
                      alt=""
                    />
                    <img
                      src={require("../../assets/image/graphic-designer.webp")}
                      width="100"
                      className="service-image on"
                      alt=""
                    />
                  </div>
                  <h3 className="google mt-3">Graphic Designing</h3>
                  <span className="text mt-2">
                    To make your business easy and attractive for your customers
                    to understand better, we give you the best graphic design.
                  </span>
                  <a href="tel:+916396617714">
                    <p className="btn read_more">Contact Us</p>
                  </a>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
