import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from 'react-router-dom'

export default function Application() {
  return (
    <React.Fragment>
      <Row>
        <Col md={12} className="offset-application mx-auto" id="apk_div">
          <div className="pricing_table py-5">
            <Row>
              <Col md={4}>
                <div className="table-data">
                  <h4>Web View Application (Apk)</h4>
                  <h3 className="table-price">4,999</h3>
                  <ul>                    
                    <li>Splash Screen</li>
                    <li>PreLoader</li>
                    <li>Web Friendly</li>
                    <li>Fully Responsive</li>                    
                  </ul>
                  <Link to="/contact-us">
                      <Button >Contact Us</Button>
                    </Link>
                </div>
              </Col>

              <Col md={4}>
                <div className="table-data">
                  <h4>Native Application (Apk)</h4>
                  <h3 className="table-price">21,999</h3>
                  <ul>
                    <li>UI/UX Design</li>
                    <li>1 Year Support</li>
                    <li>Language - JAVA</li>
                    <li>Support All Smartphone</li>
                    <li>Notification System</li>                    
                    <li>Google Map Integration</li>                    
                  </ul>
                  <Link to="/contact-us">
                      <Button >Contact Us</Button>
                    </Link>
                </div>
              </Col>

              <Col md={4}>
                <div className="table-data">
                  <h4>Flutter Application (Apk)</h4>
                  <h3 className="table-price">29,999</h3>
                  <ul>
                    <li>UI/UX Design</li>
                    <li>1 Year Support</li>
                    <li>Language - JAVA</li>
                    <li>Support All Smartphone</li>
                    <li>Notification System</li>                    
                    <li>Google Map Integration</li>                    
                  </ul>
                  <Link to="/contact-us">
                      <Button >Contact Us</Button>
                    </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
