import React from 'react'
import HomeSlider from '../sliders/HomeSlider'
import Testimonials from '../needCompo/Testimonials'
import ContactForm from '../contactForm/ContactForm'
import Service from '../needCompo/Services'
import OurWork from '../needCompo/OurWork'
import HomeAbout from '../needCompo/HomeAbout'
import Exp from '../needCompo/Experience'
import Blog from '../needCompo/Blog'

import '../../css/serviced.css'


export default function Home(){
    return (
        <>
            <HomeSlider/>
            <Service/>
            <HomeAbout/>
            <Exp/>
            <OurWork/>
            <Testimonials subheading="TESTIMONIALS FROM OUR GREATEST CLIENTS" heading="WHAT THEY SAY ABOUT US"/>
            {/* <Blog/> */}
            <ContactForm subheading="CONTACT US" heading="LET'S TALK" /> 
        </>
    )
}