import React from 'react'
import { Container, Row, Col, Tab, Tabs, Button } from "react-bootstrap";
import { Link } from 'react-router-dom'

export default function Server(){
    return (
        <React.Fragment>
            <Row>
        <Col md={10} className="offset-application mx-auto" id="apk_div">
          <div className="pricing_table py-5">
            <Row>
              <Col md={4} className="mx-auto">
                <div className="table-data">
                  <h4>Starter</h4>
                  <h3 className="table-price">11,999</h3>
                  <ul>
                    <li>10 cPanel</li>
                    <li>10 Website</li>
                    <li>Unlimited Database</li>
                    <li>Unlimited Sub Domain</li>
                    <li>Unlimited Bandwith</li>
                    <li>Free SSL for Lifetime</li>
                    <li>24/7 Technical Support</li>
                    <li>1 Year Free Support</li>
                    
                  </ul>
                  <Link to="/contact-us">
                      <Button >Contact Us</Button>
                    </Link>
                </div>
              </Col>

              <Col md={4} className="mx-auto">
                <div className="table-data">
                  <h4>Deluxe</h4>
                  <h3 className="table-price">19,999</h3>
                  <ul>
                    <li>Unlimited cPanel</li>
                    <li>Unlimited Website</li>
                    <li>Unlimited Bandwith</li>
                    <li>Unlimited Sub Domain</li>
                    <li>Unlimited Database</li>
                    <li>Free SSL for Lifetime</li>
                    <li>24/7 Technical Support</li>
                  </ul>
                  <Link to="/contact-us">
                      <Button >Contact Us</Button>
                    </Link>
                </div>
              </Col>


              <Col md={4} className="mx-auto">
                <div className="table-data">
                  <h4>Setup AWS Server</h4>
                  <h3 className="table-price">4,999</h3>
                  <ul>
                    <li>Install cPanel</li>
                    <li>Unlimited Website</li>
                    <li>Unlimited Bandwith</li>
                    <li>Unlimited Sub Domain</li>
                    <li>Unlimited Database</li>
                    <li>Free SSL for Lifetime</li>
                    <li>24/7 Technical Support</li>
                  </ul>
                  <Link to="/contact-us">
                      <Button >Contact Us</Button>
                    </Link>
                </div>
              </Col>

              
            </Row>
          </div>
        </Col>
      </Row>
        </React.Fragment>
    )
}