import React, { Component, useState } from "react";
import { useParams } from "react-router-dom";
import logo from "../../assets/banner/viplogo.png";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Heading from "../textUtils/Heading";
import Wordpress from "../serviceslist/Wordpress";
import Website from "../serviceslist/Website";
import Application from "../serviceslist/Application";
import Hosting from "../serviceslist/Hosting";
import Server from "../serviceslist/Server";
import Graphic from "../serviceslist/Graphic";

function Services() {
  const { id } = useParams();
  const [ids, setids] = useState(id);

  

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={12}>
            
            <Heading subheading="WE ARE DESCRIBE ABOUT" heading={ids} />
          </Col>
        </Row>
      </Container>

      <section id="pricing" className="py-5">
        <Container fluid>
          <Row>
            <Col md={10} className="mx-auto text-center">
              <Tabs
                defaultActiveKey={id}
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={(k) => setids(k)}
              >
                <Tab eventKey="Wordpress" title="Wordpress">
                  <Wordpress/>
                </Tab>
                <Tab eventKey="Website" title="Website">
                    <Website/>
                </Tab>
                <Tab eventKey="Application" title="Application">
                    <Application/>
                </Tab>

                <Tab eventKey="Graphic" title="Graphics">
                    <Graphic/>
                </Tab>

                <Tab eventKey="Hosting" title="Hosting">
                    <Hosting/>
                </Tab>

                <Tab eventKey="Server" title="Server">
                    <Server/>
                </Tab>
              </Tabs>
            </Col>
          </Row>
          
        </Container>
      </section>
    </>
  );
}

export default Services;
