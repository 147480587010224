import React from 'react'
import TabList from '../tab_list/TabList'

export default function Exp(){
    return (
        <>            
            <TabList/>
        </>
    )

}