import React from 'react'
import Logo from '../../assets/image/viplogo.webp'
import Heading from '../textUtils/Heading'
import {Row, Container, Col, Image } from "react-bootstrap";

export default function HomeAbout(){
    return(
        <React.Fragment>

            <Container fluid className=' py-5 homeabout'>
                {/* <img src={back} className="back_circle right"/> */}
                <Row>
                    <Col md={4} className='my-auto'>
                        <Image src={Logo} fluid/>
                    </Col>

                    <Col md={8} className="p-3">
                        <Heading subheading="WHY CHOOSE VIPSGROW" heading="KEEP UPGRADE YOUR BUSINESS ON WEB"/>
                        <p>
                        We want every business to have its own digital shop, its own
              website. So that it can go global. Its service area can be
              broadened. Digital shop like profiling website, e-commerce
              website, Android application etc.
                        </p>
                        <h4>Our Mission</h4>
                        <p>
                        We are on a mission to build a better future where every business will have its own digital shop on the web or internet
                        </p>
                    </Col>
                </Row>
            </Container>

           
        </React.Fragment>
    )
}