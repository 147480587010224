import React, { useState } from "react";
import DataContext from "./DataContext";

const ApiState = (props) => {
  const [ourwork, setOurWork] = useState([]);
  const [testimonial, settestimonial] = useState([]);
  const host = "https://node.vipsgrow.in";

  const getOurWork = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      
    };
    const response = await fetch(
      `${host}/api/getwork`,
      requestOptions
    );
    const datares = await response.json();
    console.log(datares);
    if (datares.status === "Success") {
      setOurWork(datares.data);
    }
    return datares;
  };

  const getTestimonial = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `${host}/api/testimonial`,
      requestOptions
    );
    const datares = await response.json();    
    if (datares.status === "Success") {
      settestimonial(datares.data);
    }
    // return datares;
  };

  const addContactrequest = async(formdata)=>{
    const requestOptions = {
        method:"POST",
        headers: {
          "Content-Type": "application/json",
        },
        body:JSON.stringify(formdata)
    }
    const response = await fetch(
        `${host}/api/contact`,
        requestOptions
    )
    const data = await response.json();
    return data;
  }

  return (
    <DataContext.Provider
      value={{
        ourwork,        
        getOurWork,
        testimonial,
        getTestimonial,
        addContactrequest
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};
export default ApiState;
