import React from 'react'

export default function TopBar(props){
    return(
        <>
            <div className='container-fluid topbar text-center py-2'>
                <div className='row'>
                    <div className='col-md-6'>
                        <ul>
                            {/* <li><i className='fa fa-mail'></i> Login | Register</li> */}
                            <li><i className='fa fa-phone'></i><a href="tel:6396617714"> 6396617714</a></li>
                        </ul>
                    </div>

                    <div className='col-md-6 social-link'>
                        <ul>
                            <li><a href='https://www.facebook.com/vipsgrow/' target="_blank"><i className='fa fa-facebook'></i> </a></li>
                            <li><a href='https://www.instagram.com/vipsgrow/' target="_blank"><i className='fa fa-instagram'></i> </a></li>
                            <li><a href='https://wa.me/6396617714' target="_blank"><i className='fa fa-whatsapp'></i> </a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}