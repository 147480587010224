import React, { useContext, useEffect } from "react";
import Heading from "../textUtils/Heading";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import DataContext from "../../context/DataContext";

const OurWork = () => {
  const mycontext = useContext(DataContext);
  const { ourwork, getOurWork } = mycontext;

  useEffect(() => {
    getOurWork();
  }, []);
  console.log(ourwork);

  const refresh = () => {
    console.log("get work");
    getOurWork();
  };

  //Owl Carousel Settings
  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <>
      <div className="container-fluid py-3 ourWork">
        {/* <img src={back} className="back_circle left" /> */}
        <div className="row">
          <div className="col-md-10 mx-auto">
            <i
              className="fa fa-repeat"
              onClick={() => {
                refresh();
              }}
              style={{ cursor: "pointer" }}
            ></i>
            <Heading subheading="IT'S DONE BY VIPSGROW" heading="OUR WORK" />
          </div>
          <div className="col-md-10 mx-auto">
            {ourwork.length === 0 ? (
              "No Data Found"
            ) : (
              <OwlCarousel
                id="news-slider"
                className="owl-carousel owl-theme"
                {...options}
              >
                {ourwork.map((testiMonialDetail, index) => {
                  const { name, address, description, img, date, link } =
                    testiMonialDetail;
                  return (
                    <div className="post-slide" key={index}>
                      <div className="post-img">
                        <img src={img} alt="" className='="img-fluid' />
                        <a href={link} className="over-layer">
                          <i className="fa fa-link"></i>
                        </a>
                      </div>
                      <div className="post-content">
                        <h3 className="post-title">
                          <a href={link}>{name}</a>
                        </h3>
                        <p className="post-address">{address}</p>
                        <p className="post-description">{description}</p>

                        <span className="post-date">
                          <i className="fa fa-clock-o"></i>
                          {date}
                        </span>
                        <p href="#" className="read-more">
                          {address}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurWork;
