import React from 'react';

const TestiMonialsDetails = ({testiMonialDetail, keyValue}) => {
    const {name,  description, img} = testiMonialDetail;    
    console.log(keyValue);
    return (
        <div className="item" key={keyValue}>
            <div className="shadow-effect">
                <img className="img-circle" src={img} />
                <p>{description}</p>
            </div>
            <div className="testimonial-name">
                <h5>{name}</h5>                
            </div>
        </div>
    );
};

export default TestiMonialsDetails;