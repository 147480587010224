import React from "react";
// import logo from "../../viplogo.webp";
import Logo from '../../assets/image/viplogo.webp'

import { Link } from "react-router-dom";

import { Navbar, Container, Nav, NavDropdown, Image } from "react-bootstrap";

export default function NavbarData() {
  return (
    <React.Fragment>
      <Navbar expand="lg">
        <Container>
          <Link className="navbar-brand" to="/">
            <Image src={Logo} className="App-logo" />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link className="nav-link" to="/">
                Home
              </Link>
              <Link className="nav-link" to="/about">
                About Us
              </Link>
              <Link className="nav-link" to="/plan">
                Plan
              </Link>
              <Link className="nav-link" to="/blog">
                Blog
              </Link>

              <NavDropdown title="Services" id="basic-nav-dropdown">
                <Link className="dropdown-item" to="/serviceDetails/Wordpress">
                  Wordpress Website
                </Link>
                <Link className="dropdown-item" to="/serviceDetails/Website">
                  Website (PHP, NODE JS ETC)
                </Link>
                <Link
                  className="dropdown-item"
                  to="/serviceDetails/Application"
                >
                  Application
                </Link>
                <Link className="dropdown-item" to="/serviceDetails/Graphic">
                  Graphics
                </Link>
                <Link className="dropdown-item" to="/serviceDetails/Hosting">
                  Hosting
                </Link>
                <Link className="dropdown-item" to="/serviceDetails/Server">
                  Server
                </Link>

                <NavDropdown.Divider />
              </NavDropdown>
              <Link className="nav-link" to="/contact-us">
                Contact Us
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* <nav className="navbar navbar-expand-lg ">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} className="img-fluid App-logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link " aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  About Us
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="#">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/plan">
                  Plan
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/blog">
                  Blog
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/contact-us">
                  Contact Us
                </Link>
              </li>
            </ul>
            
          </div>
        </div>
      </nav> */}
    </React.Fragment>
  );
}
