import React, { useContext, useEffect } from "react";
import Heading from "../textUtils/Heading";
import Logo from "../../viplogo.png";

import TestiMonialsDetails from "./TestiMonialsDetails";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { Row, Col } from "react-bootstrap";
import DataContext from "../../context/DataContext";
function Testimonials(props) {
  const dataContext = useContext(DataContext);
  const { getTestimonial, testimonial } = dataContext;

  useEffect(() => {
    getTestimonial();
  }, []);

  const testiMonials = testimonial;
  // const testiMonials = [
  //   {
  //     name: "Rekob Ramya",
  //     description:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.",

  //     img: "https://i.ibb.co/hgGJc8d/Gareth-Bale.jpg",
  //   },
  //   {
  //     name: "Brandon Savage",
  //     description:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.",

  //     img: "https://i.ibb.co/z7Kp6yr/np-file-33188.jpg",
  //   },
  //   {
  //     name: "Steve Burns",
  //     description:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.",

  //     img: "https://i.ibb.co/CP5sj7g/2856040-58866808-2560-1440.jpg",
  //   },
  //   {
  //     name: "Kevin Canlas",
  //     description:
  //       "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.",

  //     img: "https://i.ibb.co/10SYccm/1552313010-354215-noticia-normal.jpg",
  //   },
  // ];
  //Owl Carousel Settings

  const options = {
    loop: true,
    center: true,
    items: 3,
    margin: 0,
    autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <>
      <section id="testimonial" className="testimonials py-5">
        <div className="container-fluid">
          <Row>
            <Col md={10} className="mx-auto">
              <Heading subheading={props.subheading} heading={props.heading} />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <OwlCarousel
                id="customer-testimonoals"
                className="owl-carousel owl-theme"
                {...options}
              >
                {testiMonials.length === 0 ? (
                  <div className="item">
                    <div className="shadow-effect">
                      <img className="img-circle" src={Logo} alt="img" />

                      <p>No Data Found</p>
                    </div>
                    <div className="testimonial-name">
                      <h5>Vishant Soni</h5>
                    </div>
                  </div>
                ) : (
                  testiMonials.map((testiMonialDetail, index) => {
                    console.log("index = " + index);
                    return (
                      <div key={testiMonialDetail.id}>
                        <TestiMonialsDetails
                          testiMonialDetail={testiMonialDetail}
                          keyValue={index}
                        />
                      </div>
                    );
                  })
                )}
              </OwlCarousel>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
