import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from 'react-router-dom'

export default function Website() {
  return (
    <React.Fragment>
      <Row>
        <Col md={10} className="offset-application mx-auto" id="apk_div">
          <div className="pricing_table py-5">
            <Row>
              <Col md={4}>
                <div className="table-data">
                  <h4>PHP Website</h4>
                  <h3 className="table-price">4,999</h3>
                  <ul>
                    <li>1 Year Hosting</li>
                    <li>Free SSL for Lifetime</li>
                    <li>1 Year Free Support</li>
                    <li>Development Time : 3 Days</li>
                    <li>Fully Responsive Website</li>
                    <li>Contact Form</li>
                    <li>Google Map Integration</li>
                    <li>Social Media Integration</li>
                    <li>Search Engine (SEO) Friendly</li>
                    <li>One Page SEO</li>
                  </ul>
                  <Link to="/contact-us">
                      <Button >Contact Us</Button>
                    </Link>
                </div>
              </Col>

              <Col md={4}>
                <div className="table-data">
                  <h4>Node JS / React JS Website</h4>
                  <h3 className="table-price">19,999</h3>
                  <ul>
                    <li>1 Year Hosting</li>
                    <li>Free SSL for Lifetime</li>
                    <li>1 Year Free Support</li>
                    <li>Development Time : 7 Days</li>
                    <li>Fully Responsive Dynamic Website</li>
                    <li>Contact Form</li>
                    <li>Appearance On Google Map</li>
                    <li>Google Map Integration</li>
                    <li>Social Media Account Creation</li>
                    <li>Social Media Integration</li>
                    <li>Search Engine (SEO) Friendly</li>
                    <li>One Page SEO</li>
                    <li>Intuitive Navigation To Browse Site</li>
                  </ul>
                    <Link to="/contact-us">
                      <Button >Contact Us</Button>
                    </Link>
                </div>
              </Col>

              <Col md={4}>
                <div className="table-data">
                  <h4>Web Software</h4>
                  <h3 className="table-price">Starting - 14,999</h3>
                  <ul>
                    <li>1 Year Hosting</li>
                    <li>Free SSL for Lifetime</li>
                    <li>1 Year Free Support</li>
                    <li>Development Time : 5 Days</li>
                    <li>Fully Responsive Website</li>
                    <li>Contact Form & Control Panel</li>
                    <li>Google Map Integration</li>
                    <li>Social Media Integration</li>
                    <li>Search Engine (SEO) Friendly</li>
                    <li>One Page SEO</li>
                  </ul>
                  <Link to="/contact-us">
                      <Button >Contact Us</Button>
                    </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
