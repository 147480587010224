import "./App.css";
import "./css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Navbar from "./componants/common/MyNavbar";
import Home from "./componants/pages/Home";
import Footer from "./componants/common/Footer";
import Copyright from "./componants/common/Copyright";
import About from "./componants/pages/About";
import ReviewPopup from "./componants/common/ReviewPopup";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Plan from "./componants/pages/Plan";
import ContactPage from "./componants/pages/ContactUsPage";
import Blog from "./componants/pages/Blog";
import TopBar from "./componants/needCompo/TopBar";
import Service from "./componants/pages/Services";
import Refund from "./componants/pages/Refund_polcy";
import Privacy from "./componants/pages/Privacy";
import TermsCondition from "./componants/pages/Terms_condition";
import OurProject from "./componants/pages/Our-Projects";
import ApiState from "./context/ApiState";

function App() {
  return (
    <>
      <ApiState>
        <Router>
          <div className="myheader">
            <TopBar />
            <Navbar />
          </div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/plan" element={<Plan />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/plan" element={<Plan />} />
            <Route path="/refund-policy" element={<Refund />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/terms-condition" element={<TermsCondition />} />

            <Route path="/serviceDetails/:id" element={<Service />} />
            <Route path="/contact-us" element={<ContactPage />} />
            <Route path="/our-projects" element={<OurProject />} />
          </Routes>
          <Footer />
        </Router>
        <ReviewPopup />
        <Copyright />
      </ApiState>
    </>
  );
}
export default App;
