import React from 'react'
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";

export default function Graphic(){
    return (
        <React.Fragment>
            <Row>
        <Col md={12} className="offset-application mx-auto" id="apk_div">
          <div className="pricing_table py-5">
            <Row>
              <Col md={3}>
                <div className="table-data">
                  <h4>Web Template (Website)</h4>
                  <h3 className="table-price">2,999</h3>
                  
                </div>
              </Col>

              <Col md={3}>
                <div className="table-data">
                  <h4>Logo Design</h4>
                  <h3 className="table-price">699</h3>                 
                </div>
              </Col>

              <Col md={3}>
                <div className="table-data">
                  <h4>Brochure Design</h4>
                  <h3 className="table-price">200 per page</h3>
                </div>
              </Col>

              <Col md={3}>
                <div className="table-data">
                  <h4>Mothly Plan </h4>
                  <ul>
                    <li>Festival Banner</li>
                    <li>Product Banner</li>
                    <li>Fetured Design</li>
                  </ul>
                  <h3 className="table-price">4,999 per month</h3>
                </div>
              </Col>

            </Row>
          </div>
        </Col>
      </Row>
        </React.Fragment>
    )
}