import React, { useContext, useState } from 'react'
import DataContext from '../../context/DataContext'
import Heading from '../textUtils/Heading'



export default function ContactForm(props) {

    const dataContext = useContext(DataContext);
    const {addContactrequest} = dataContext;
    const [newContact, setNewContact] = useState({
        name:"",
        phone:"",
        message:"",
        email:"",
        subject:""
    })
    const onchange =(e)=>{
        setNewContact({...newContact, [e.target.name]:e.target.value})
    }
    const add_sub = async(e) =>{
        e.preventDefault();                
        const formdata = new FormData();
        formdata.append("name", newContact.name);
        formdata.append("email", newContact.email);
        formdata.append("message", newContact.message);
        formdata.append("phone", newContact.phone);
        formdata.append("subject", newContact.subject);
        const res = await addContactrequest(newContact);
        
        
        if (res.status==="Success") {
            alert("Your Request has been submitted. We contact with you shortly")            
        }else{
            alert(res.message);
        }
    }


    return (
        <>        
        <div className='container-fluid py-5 contact_us_section'>
            <div className='row'>
                <div className='col-md-10 mx-auto'>
                    <Heading subheading={props.subheading} heading={props.heading}/>                   
                    
                    <div className='messagefor'>
                        <div className='row'>
                            <div className='col-md-6 col-6'>
                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Name</label>
                                    <input type="text" className="form-control" name="name" 
                                    id="" aria-describedby="helpId" placeholder="Enter Name" onChange={onchange}/>                          
                                </div>
                            </div>

                            <div className='col-md-6 col-6'>
                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Mobile</label>
                                    <input type="text" className="form-control" name="phone" id="" aria-describedby="helpId" placeholder="Enter Phone" onChange={onchange}/>                          
                                </div>
                            </div>

                            <div className='col-md-6 col-6'>
                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Email</label>
                                    <input type="email" className="form-control" name="email" id="" aria-describedby="helpId" placeholder="Enter Email" onChange={onchange}/>
                                </div>
                            </div>

                            <div className='col-md-6 col-6'>
                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Subject</label>
                                    <input type="email" className="form-control" name="subject" id="" aria-describedby="helpId"
                                     placeholder="Enter Subject" onChange={onchange}/>
                                </div>
                            </div>


                            <div className='col-md-12'>
                                <div className="mb-3">
                                    <label htmlFor="" className="form-label">Message</label>
                                    <textarea className='form-control' name="message" id="" rows={5} placeholder='Enter Message' 
                                    onChange={onchange}/>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <button className='default-btn submit-btn' onClick={add_sub}> Submit</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>            
        </div>
        </>
    )
  }